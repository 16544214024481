import React from "react"
import { Helmet } from "react-helmet"
const Header = () => {
  return (
    <>
      <Helmet>
        <meta charset="utf-8" />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, shrink-to-fit=no"
        />
        <link
          rel="stylesheet"
          href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css"
        ></link>
        {/* <!-- Google Tag Manager --> */}

        <script
          dangerouslySetInnerHTML={{
            __html: `  (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
                new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
                j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
                'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
                })(window,document,'script','dataLayer','GTM-MJ3X7N2');`,
          }}
        />
      </Helmet>
      <noscript>
        <iframe
          src="https://www.googletagmanager.com/ns.html?id=GTM-MJ3X7N2"
          height="0"
          width="0"
          style={{ display: "none", visibility: "hidden" }}
          // style="display:none;visibility:hidden"
        ></iframe>
      </noscript>
    </>
  )
}

export default Header
